function isDocumentAlreadyReady() {
  return document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading'
}

export default function ready(fn) {
  if (isDocumentAlreadyReady()) {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}
