import _forEach from 'lodash/forEach'

import ready from './lib/ready'

const expandedAttr = 'aria-expanded'

const toggleMenu = (button, menu) => (event) => {
  event.preventDefault()

  const toggleValue = button.getAttribute(expandedAttr) === 'true' ? false : true

  button.setAttribute(expandedAttr, toggleValue)
  menu.setAttribute(expandedAttr, toggleValue)
}

ready(() => {
  _forEach(document.querySelectorAll('.c-navbar'), (navbar) => {
    const button = navbar.querySelector('.c-navbar__toggle')
    const menu = navbar.querySelector('.c-navbar__menu')
    const backdrop = navbar.querySelector('.c-navbar__menu-backdrop')
    if (button) {
      button.addEventListener('click', toggleMenu(button, menu))
      backdrop.addEventListener('click', toggleMenu(button, menu))
    }
  })
})
