import ready from './lib/ready'

ready(() => {
  const component = document.querySelector('.js-legal-text')

  if (component) {
    const link = component.querySelector('.js-legal-text__link')
    const legalBody = component.querySelector('.js-legal-text__body')

    link.addEventListener('click', (event) => {
      event.preventDefault()
      legalBody.classList.toggle('u-hidden-sm-down')

      return false
    })
  }
})
